// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import invoices from './invoices'
import lookups from './lookups'
import machines from './machines'
import { invoiceApi } from './api/invoiceApi'

const rootReducer = {
  "invoiceApi": invoiceApi.reducer,
  navbar,
  layout,
  invoices,
  lookups,
  machines,
}

export default rootReducer
