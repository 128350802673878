import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query";
import { invoiceApi } from './api/invoiceApi';
import rootReducer from './rootReducer';
// Import other reducers as needed

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(invoiceApi.middleware),
});
setupListeners(store.dispatch)