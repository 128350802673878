import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseApi';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    searchInvoices: builder.mutation({
      query: (params) => ({
        url: '/invoice/search',
        method: 'POST',
        data: params,
      }),
    }),
    downloadInvoicePdf: builder.query({
      query: (invoiceId) => ({
        url: `/invoice/download-invoice-file/${invoiceId}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const { 
  useSearchInvoicesMutation, 
  useDownloadInvoicePdfQuery,
  useLazyDownloadInvoicePdfQuery // Add this line
} = invoiceApi;